import React, { useState } from 'react'
import authentication from '../assets/authentication.jpg'
import logo from '../assets/logo.png'
import { NavLink } from 'react-router-dom'
import { FaRegUser } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { HiOutlinePhone } from "react-icons/hi";
import { MdOutlineEmail } from "react-icons/md";


const Signup = () => {
  const [passwordToggle, setPasswordToggle] = useState(false)

  return (
    <div className='doctor-theme'>
      <div className='authentication-form'>
        <form action="" method="post">
          <div className='row align-items-center  py-lg-5'>
            <div className='col-lg-6'>
              <img src={authentication} className='img-fluid w-75 mx-auto d-lg-block d-none' alt="images" />
            </div>
            <div className='col-lg-1 d-lg-block d-none'></div>
            <div className='col-lg-4'>
              <div className='p-lg-0 p-3'>
                <img src={logo} className='img-fluid mt-3 mb-lg-3 bg-blue p-2 px-4 d-block mx-auto' width={200} style={{ borderRadius: 30 }} alt="images" />
                <h1 className='text-uppercase text-center my-3'>Signup</h1>
                <span className='mx-auto text-muted w-max d-block my-3'>Let’s get started</span>
                <div className='input-icons'>
                  <input type="email" className='mb-3' placeholder='Username' />
                  <FaRegUser />
                </div>
                <div className='input-icons'>
                  <input type="number" className='mb-3' placeholder='Phone Number' />
                  <HiOutlinePhone />
                </div>
                <div className='input-icons'>
                  <input type="email" className='mb-3' placeholder='Email Address' />
                  <MdOutlineEmail />
                </div>
                <div className='input-icons'>
                  <input type={passwordToggle ? 'text' : 'password'} className='mb-3' placeholder='Password' />
                  <FiLock />
                  <span className='password-icon' onClick={() => passwordToggle ? setPasswordToggle(false) : setPasswordToggle(true)}>
                    {
                      (passwordToggle ? <IoEyeOutline />
                        : <IoEyeOffOutline />
                      )
                    }
                  </span>
                </div>
                <button className='btn theme-btn btn btn-primary px-5 mt-3 w-max d-block btn-lg mx-auto'>Signup</button>
                <span className='mt-4 d-block text-center text-muted small'>Already have an account? <NavLink to='/login' className='d-lg-inline d-block'>Login</NavLink></span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Signup