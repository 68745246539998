import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard'
import Patients from './pages/patients/Patients'
import AddPatients from './pages/patients/AddPatients'
import PatientDetails from './pages/patients/PatientDetails';
import PatientNotes from './pages/patients/PatientNotes';
import Notifications from './pages/Notifications';
import Calendars from './pages/Calendars'
import Account from './pages/Account';
import Notes from './pages/notes/Notes';
import CreateNote from './pages/notes/CreateNote';
import Forms from './pages/Forms';
import Help from './pages/Help';
import CreatePrescription from './pages/prescription/CreatePrescription';
import PatientsPrescription from './pages/patients/PatientsPrescription';
import Prescription from './pages/prescription/Prescription';
import Chat from './pages/chats/Chat';
import Appointment from './pages/appointments/Appointment';
import BookAppointment from './pages/appointments/BookAppointment';
import Login from './pages/Login';
import Signup from './pages/Signup';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Dashboard />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/signup' element={<Signup />}></Route>
          <Route path='/appointment' element={<Appointment />}></Route>
          <Route path='/book-appointment' element={<BookAppointment />}></Route>
          <Route path='/patients' element={<Patients />}></Route>
          <Route path='/patients/:id' element={<PatientDetails />} />
          <Route path='/patient/notes/:id' element={<PatientNotes />} />
          <Route path='/patients/add-patients' element={<AddPatients />}></Route>
          <Route path='/patients/prescription/:id' element={<PatientsPrescription />}></Route>
          <Route path='/prescription/create-prescription' element={<CreatePrescription />}></Route>
          <Route path='/prescription' element={<Prescription />}></Route>
          <Route path='/calendar' element={<Calendars />}></Route>
          <Route path='/notifications' element={<Notifications />}></Route>
          <Route path='/account' element={<Account />}></Route>
          <Route path='/notes' element={<Notes />}></Route>
          <Route path='/notes/create-note' element={<CreateNote />}></Route>
          <Route path='/forms' element={<Forms />}></Route>
          <Route path='/chat' element={<Chat />}></Route>
          <Route path='/help' element={<Help />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
