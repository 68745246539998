import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import ChatList from './ChatList'
import ChatBox from './ChatBox'

const Chat = () => {
    const [mobileChatToggle, setmobileChatToggle] = useState(false)
    return (
        <Layout style={{ padding: 0 }}>
            <div className='row mx-0'>
                <div className='col-lg-12 px-0'>
                    <div className={`chatbox ${mobileChatToggle ? 'toggleMobileChat' : ''}`}>
                        <ChatList setmobileChatToggle={setmobileChatToggle} />
                        <ChatBox setmobileChatToggle={setmobileChatToggle} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Chat