import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { NavLink } from 'react-router-dom'
import { CiSearch } from 'react-icons/ci'
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const Patients = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <Layout>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='title-wrapper'>
                        <h4>Patients</h4>
                    </div>
                </div>
                <div className='col-lg-8'>
                    <div className='widget-content'>
                        <div className='patients-table-box'>
                            <div className='mb-3 d-flex align-items-center justify-content-end gap-3 w-100'>
                                <div className='filter-search'>
                                    <input type="text" placeholder='Search...' />
                                    <CiSearch />
                                </div>
                                <NavLink to='/patients/add-patients' className='theme-btn btn btn-primary ms-auto'>Add Patient</NavLink>
                            </div>
                            <table className='table patients-table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th colSpan={2}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>
                                                <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" alt="" />
                                                <span>John</span>
                                            </div>
                                        </td>
                                        <td><span className='badge bg-success'>Active</span></td>
                                        <td>3-29-2024</td>
                                        <td><NavLink to='/patients/5' className='btn btn-primary bg-blue btn-sm'>View</NavLink></td>
                                        <td><button className='btn btn-primary bg-blue btn-sm' onClick={handleShow}>Send Form</button></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" alt="" />
                                                <span>John</span>
                                            </div>
                                        </td>
                                        <td><span className='badge bg-success'>Active</span></td>
                                        <td>3-29-2024</td>
                                        <td><NavLink to='/patients/5' className='btn btn-primary bg-blue btn-sm'>View</NavLink></td>
                                        <td><button className='btn btn-primary bg-blue btn-sm' onClick={handleShow}>Send Form</button></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" alt="" />
                                                <span>John</span>
                                            </div>
                                        </td>
                                        <td><span className='badge bg-success'>Active</span></td>
                                        <td>3-29-2024</td>
                                        <td><NavLink to='/patients/5' className='btn btn-primary bg-blue btn-sm'>View</NavLink></td>
                                        <td><button className='btn btn-primary bg-blue btn-sm' onClick={handleShow}>Send Form</button></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" alt="" />
                                                <span>John</span>
                                            </div>
                                        </td>
                                        <td><span className='badge bg-success'>Active</span></td>
                                        <td>3-29-2024</td>
                                        <td><NavLink to='/patients/5' className='btn btn-primary bg-blue btn-sm'>View</NavLink></td>
                                        <td><button className='btn btn-primary bg-blue btn-sm' onClick={handleShow}>Send Form</button></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" alt="" />
                                                <span>John</span>
                                            </div>
                                        </td>
                                        <td><span className='badge bg-success'>Active</span></td>
                                        <td>3-29-2024</td>
                                        <td><NavLink to='/patients/5' className='btn btn-primary bg-blue btn-sm'>View</NavLink></td>
                                        <td><button className='btn btn-primary bg-blue btn-sm' onClick={handleShow}>Send Form</button></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" alt="" />
                                                <span>John</span>
                                            </div>
                                        </td>
                                        <td><span className='badge bg-success'>Active</span></td>
                                        <td>3-29-2024</td>
                                        <td><NavLink to='/patients/5' className='btn btn-primary bg-blue btn-sm'>View</NavLink></td>
                                        <td><button className='btn btn-primary bg-blue btn-sm' onClick={handleShow}>Send Form</button></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" alt="" />
                                                <span>John</span>
                                            </div>
                                        </td>
                                        <td><span className='badge bg-success'>Active</span></td>
                                        <td>3-29-2024</td>
                                        <td><NavLink to='/patients/5' className='btn btn-primary bg-blue btn-sm'>View</NavLink></td>
                                        <td><button className='btn btn-primary bg-blue btn-sm' onClick={handleShow}>Send Form</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className='widget-content'>
                        <div className='disclamer-box'>
                            <h5>Disclaimer</h5>
                            <p>Create patient record by entering their name and contact information</p>
                            <p>This will send an email with a pre-consultation form they must complete to book an appointment on the calendar</p>
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Send Form </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <div className='d-flex align-items-center gap-3 border-bottom p-3'>
                            <div>
                                <h6>Patient Name</h6>
                                <span>Ahmed Raza</span>
                            </div>
                        </div>
                        <div className='row mx-0 mt-3'>
                            <div className='col-lg-12'>
                                <label htmlFor="">Select Form</label>
                                <select>
                                    <option value="" selected>Select Form</option>
                                    <option value="">Form 1</option>
                                    <option value="">Form 2</option>
                                    <option value="">Form 3</option>
                                    <option value="">Form 4</option>
                                    <option value="">Form 5</option>
                                </select>
                            </div>
                        </div>
                        <div className='p-3'>
                            <CKEditor
                                editor={ClassicEditor}
                                data="<p>Hello from CKEditor 5!</p><h2>Try the inspector below</h2><ul><li>Check the Model</li><li>See the View</li><li>Check available commands</li></ul>"
                                // onReady={(editor) => {
                                //     console.log('CKEditor React Component is ready to use!', editor);
                                //     CKEditorInspector.attach(editor);
                                // }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    console.log({ event, editor, data });
                                }}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={handleClose}>Close</button>
                        <button className='btn btn-primary' onClick={handleClose}>Send</button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Layout>
    )
}

export default Patients