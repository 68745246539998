import React from 'react'
import { NavLink } from 'react-router-dom'
import { CiSearch } from 'react-icons/ci'

const ChatList = ({ setmobileChatToggle }) => {
    return (
        <div className='chat-list'>
            <div className='chat-list-header'>
                <h4>Chats</h4>
                <div className='filter-search w-100'>
                    <input type="text" placeholder='Search...' />
                    <CiSearch />
                </div>
            </div>
            <ul>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => setmobileChatToggle(true)}>
                        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
                        <div className='chat-detail'>
                            <h6>Ahmed Raza</h6>
                            <span>Thank you very much. I’m glad ...</span>
                        </div>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default ChatList