import React, { useRef, useEffect } from 'react';
import { RiSendPlaneFill } from "react-icons/ri";
import { MdArrowBack } from "react-icons/md";
import { useState } from 'react';

const ChatBox = ({ setmobileChatToggle }) => {

  const myMessages = [
    {
      "status": "success",
      "message": "Message sent successfully",
      "data": {
        "message_id": "1",
        "timestamp": "2024-04-18T12:00:00Z",
        "sender_id": "user123",
        "recipient_id": "bot456",
        "message": "Hello, how are you?"
      }
    },
    {
      "status": "success",
      "message": "Message received successfully",
      "data": {
        "message_id": "2",
        "timestamp": "2024-04-18T12:01:00Z",
        "sender_id": "bot456",
        "recipient_id": "user123",
        "message": "Hi there! I'm doing well, thank you for asking."
      }
    },
    {
      "status": "success",
      "message": "Message received successfully",
      "data": {
        "message_id": "3",
        "timestamp": "2024-04-18T12:01:00Z",
        "sender_id": "user123",
        "recipient_id": "bot456",
        "message": "Hi there! I'm doing well, thank."
      },
    }
  ]

  const Message = ({ senderId, message }) => {
    return (
      <div className={`chat-message ${senderId === "user123" ? "sender-message" : "recipient-message"}`}>
        {message}
      </div>
    );
  };

  const [messages, setMessages] = useState(myMessages);
  const [newMessages, setNewMessages] = useState('');
  const [count, setCount] = useState(4);


  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollableContainerRef = useRef(null);

  const scrollToBottom = () => {
    const container = scrollableContainerRef.current;
    if (container) {
      const scrollHeight = container.scrollHeight;
      container.scrollTo({ top: scrollHeight, behavior: 'smooth' });
      // Update state if necessary
      setScrollPosition(scrollHeight);
    }
  };

  const SendMessage = (e) => {
    e.preventDefault();
    setCount(prevCount => prevCount + 1);

    if (newMessages !== '') {
      messages.push({
        "status": "success",
        "message": "Message sent successfully",
        "data": {
          "message_id": count,
          "timestamp": "2024-04-18T12:00:00Z",
          "sender_id": "bot456",
          "recipient_id": "user123",
          "message": newMessages
        }
      });
    }
    setNewMessages('');
  }

  useEffect(() => {
    scrollToBottom();
    console.log('hahah');
  }, [count])

  return (
    <div className='chat-inbox'>
      <div className='chat-inbox-header'>
        <img src="https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=ais" className='chat-image' alt="profile" />
        <div className='chat-detail'>
          <h6>Ahmed Raza</h6>
        </div>
        <div className='d-lg-none d-block arrowback' onClick={() => setmobileChatToggle(false)}>
          <MdArrowBack />
        </div>
      </div>
      <div className='chat-body' ref={scrollableContainerRef}>
        {messages.map(message => (
          <Message
            key={message.data.message_id}
            senderId={message.data.sender_id}
            message={message.data.message}

          />
        ))}
      </div>
      <div className='chat-footer'>
        <form onSubmit={SendMessage} className='w-100'>
          <input type="text" placeholder='Type your message' name='newMessage' value={newMessages} onChange={(e) => setNewMessages(e.target.value)} />
          <button type='submit' className='send-message btn-primary' >
            <RiSendPlaneFill />
          </button>
        </form>
      </div>
    </div>
  )
}

export default ChatBox